<template>
  <div :class="`flex justify-center !min-h-[${props.size}] !min-w-[${props.size}]`">
    <svg
      viewBox="25 25 50 50"
      :class="`fill-none stroke-primary-500 stroke-[3]`"
      :style="{
        minHeight: props.size,
        minWidth: props.size,
        width: props.size,
        height: props.size,
      }"
    >
      <circle r="20" cy="50" cx="50"></circle>
    </svg>
  </div>
</template>

<script setup>
const props = defineProps({
  size: {
    type: String,
    default: '2.25rem',
    required: false,
  },
});
</script>

<style scoped>
svg {
  transform-origin: center;
  animation: rotate 2s linear infinite;
}

circle {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}
</style>
